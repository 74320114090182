import React, { useState } from 'react';
import {
  Container,
  Typography,
  TextField,
  Button,
  Box,
  Grid,
  Paper,
} from '@mui/material';
import { useNavigate, useLocation } from 'react-router-dom';
import axios from '../utils/axios';

const ForgotPassword = () => {
  const [email, setEmail] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmNewPassword, setConfirmNewPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const token = queryParams.get('token');

  const handleSubmitEmail = async (e) => {
    e.preventDefault();
    setLoading(true);
    setMessage('');
    setError('');

    try {
      const response = await axios.post('/api/auth/resetPassword', { email });
      setMessage(response.data.msg);
    } catch (err) {
      setError(
        err.response?.data?.msg ||
          'Something went wrong. Please try again later.'
      );
    } finally {
      setLoading(false);
    }
  };

  const handleSubmitPassword = async (e) => {
    e.preventDefault();
    if (newPassword !== confirmNewPassword) {
      setError('Passwords do not match');
      return;
    }

    setLoading(true);
    setMessage('');
    setError('');

    try {
      const response = await axios.put(
        '/api/auth/updatePassword',
        { password: newPassword },
        { headers: { 'x-auth-token': token } }
      );
      setMessage(response.data.msg);
    } catch (err) {
      setError(
        err.response?.data?.msg ||
          'Something went wrong. Please try again later.'
      );
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container component='main' maxWidth='xs'>
      <Paper elevation={3} sx={{ p: 4, mt: 8 }}>
        {!token ? (
          <>
            <Typography component='h1' variant='h5' align='center'>
              Forgot Password
            </Typography>
            <Typography variant='body2' align='center' sx={{ mt: 1, mb: 3 }}>
              Enter your email address and we will send you a link to reset your
              password.
            </Typography>
            <Box
              component='form'
              onSubmit={handleSubmitEmail}
              noValidate
              sx={{ mt: 1 }}
            >
              <TextField
                margin='normal'
                required
                fullWidth
                id='email'
                label='Email Address'
                name='email'
                autoComplete='email'
                autoFocus
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              {message && (
                <Typography
                  variant='body2'
                  color='success.main'
                  align='center'
                  sx={{ mt: 2 }}
                >
                  {message}
                </Typography>
              )}
              {error && (
                <Typography
                  variant='body2'
                  color='error'
                  align='center'
                  sx={{ mt: 2 }}
                >
                  {error}
                </Typography>
              )}
              <Button
                type='submit'
                fullWidth
                variant='contained'
                color='primary'
                disabled={loading}
                sx={{ mt: 3, mb: 2 }}
              >
                {loading ? 'Sending...' : 'Send Reset Link'}
              </Button>
              <Grid container justifyContent='flex-end'>
                <Grid item>
                  <Button
                    onClick={() => navigate('/')}
                    variant='text'
                    color='primary'
                  >
                    Back to Login
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </>
        ) : (
          <>
            <Typography component='h1' variant='h5' align='center'>
              Reset Password
            </Typography>
            <Typography variant='body2' align='center' sx={{ mt: 1, mb: 3 }}>
              Enter your new password below.
            </Typography>
            <Box
              component='form'
              onSubmit={handleSubmitPassword}
              noValidate
              sx={{ mt: 1 }}
            >
              <TextField
                margin='normal'
                required
                fullWidth
                name='newPassword'
                label='New Password'
                type='password'
                id='newPassword'
                autoComplete='new-password'
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
              />
              <TextField
                margin='normal'
                required
                fullWidth
                name='confirmNewPassword'
                label='Confirm New Password'
                type='password'
                id='confirmNewPassword'
                autoComplete='new-password'
                value={confirmNewPassword}
                onChange={(e) => setConfirmNewPassword(e.target.value)}
              />
              {message && (
                <Typography
                  variant='body2'
                  color='success.main'
                  align='center'
                  sx={{ mt: 2 }}
                >
                  {message}
                </Typography>
              )}
              {error && (
                <Typography
                  variant='body2'
                  color='error'
                  align='center'
                  sx={{ mt: 2 }}
                >
                  {error}
                </Typography>
              )}
              <Button
                type='submit'
                fullWidth
                variant='contained'
                color='primary'
                disabled={loading}
                sx={{ mt: 3, mb: 2 }}
              >
                {loading ? 'Resetting...' : 'Reset Password'}
              </Button>
              <Grid container justifyContent='flex-end'>
                <Grid item>
                  <Button
                    onClick={() => navigate('/')}
                    variant='text'
                    color='primary'
                  >
                    Back to Login
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </>
        )}
      </Paper>
    </Container>
  );
};

export default ForgotPassword;

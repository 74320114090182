import React, { useEffect, useState } from 'react';
import axios from '../utils/axios';
import {
  Button,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  InputAdornment,
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';

const EmailCampaigns = () => {
  const [campaigns, setCampaigns] = useState([]);
  const [nurturingLead, setNurturingLead] = useState({
    name: '',
    frequency: '',
    period: 'Days',
    subject: '',
    content: '',
    type: 'Nurturing Leads',
  });
  const [curiousLead, setCuriousLead] = useState({
    name: '',
    frequency: '',
    period: 'Days',
    subject: '',
    content: '',
    type: 'Curious Leads',
  });
  const [notReadyToSellLead, setNotReadyToSellLead] = useState({
    name: '',
    frequency: '',
    period: 'Days',
    subject: '',
    content: '',
    type: 'Not Ready to Sell Leads',
  });
  const [open, setOpen] = useState(false);
  const [dialogTitle, setDialogTitle] = useState('');
  const [selectedCampaign, setSelectedCampaign] = useState(null);

  useEffect(() => {
    const fetchEmailCampaigns = async () => {
      try {
        const res = await axios.get('/api/emailCampaigns');
        const campaigns = res.data;
        const nurturing = campaigns.find((c) => c.type === 'Nurturing Leads');
        const curious = campaigns.find((c) => c.type === 'Curious Leads');
        const notReadyToSell = campaigns.find(
          (c) => c.type === 'Not Ready to Sell Leads'
        );
        const others = campaigns.filter((c) => c.type === 'Others');
        setCampaigns(others);
        setNurturingLead(nurturing || nurturingLead);
        setCuriousLead(curious || curiousLead);
        setNotReadyToSellLead(notReadyToSell || notReadyToSellLead);
      } catch (err) {
        console.error(err);
        alert('Failed to fetch email campaigns.');
      }
    };

    fetchEmailCampaigns();
  }, []);

  const handleAddCampaign = () => {
    setSelectedCampaign({
      name: '',
      frequency: '',
      period: 'Days',
      subject: '',
      content: '',
      type: 'Others',
    });
    setDialogTitle(`Add Email Campaign`);
    setOpen(true);
  };

  const handleChange = (field, value, section) => {
    if (section === 'Nurturing Leads') {
      setNurturingLead({ ...nurturingLead, [field]: value });
    } else if (section === 'Curious Leads') {
      setCuriousLead({ ...curiousLead, [field]: value });
    } else if (section === 'Not Ready to Sell Leads') {
      setNotReadyToSellLead({ ...notReadyToSellLead, [field]: value });
    } else {
      setSelectedCampaign({ ...selectedCampaign, [field]: value });
    }
  };

  const handleEdit = (campaign) => {
    setSelectedCampaign({ ...campaign });
    setDialogTitle(`Edit Email Campaign`);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedCampaign(null);
  };

  const handleSave = async (section) => {
    try {
      let campaign;

      if (section === 'Nurturing Leads') {
        campaign = nurturingLead;
      } else if (section === 'Curious Leads') {
        campaign = curiousLead;
      } else if (section === 'Not Ready to Sell Leads') {
        campaign = notReadyToSellLead;
      } else {
        campaign = selectedCampaign;
      }

      if (
        !campaign.name ||
        !campaign.frequency ||
        !campaign.period ||
        !campaign.subject ||
        !campaign.content
      ) {
        alert('All fields are required.');
        return;
      }

      const frequency = Number(campaign.frequency);
      if (frequency <= 0 || !Number.isInteger(frequency)) {
        alert('Sending Frequency must be a positive integer.');
        return;
      }

      let res;
      if (section === 'Nurturing Leads') {
        if (nurturingLead._id) {
          res = await axios.put(
            `/api/emailCampaigns/${nurturingLead._id}`,
            nurturingLead
          );
        } else {
          res = await axios.post(`/api/emailCampaigns`, {
            ...nurturingLead,
            type: 'Nurturing Leads',
          });
        }
        setNurturingLead(res.data);
      } else if (section === 'Curious Leads') {
        if (curiousLead._id) {
          res = await axios.put(
            `/api/emailCampaigns/${curiousLead._id}`,
            curiousLead
          );
        } else {
          res = await axios.post(`/api/emailCampaigns`, {
            ...curiousLead,
            type: 'Curious Leads',
          });
        }
        setCuriousLead(res.data);
      } else if (section === 'Not Ready to Sell Leads') {
        if (notReadyToSellLead._id) {
          res = await axios.put(
            `/api/emailCampaigns/${notReadyToSellLead._id}`,
            notReadyToSellLead
          );
        } else {
          res = await axios.post(`/api/emailCampaigns`, {
            ...notReadyToSellLead,
            type: 'Not Ready to Sell Leads',
          });
        }
        setNotReadyToSellLead(res.data);
      } else if (selectedCampaign._id) {
        res = await axios.put(
          `/api/emailCampaigns/${selectedCampaign._id}`,
          selectedCampaign
        );
        setCampaigns(
          campaigns.map((c) => (c._id === selectedCampaign._id ? res.data : c))
        );
      } else {
        res = await axios.post('/api/emailCampaigns', selectedCampaign);
        setCampaigns([...campaigns, res.data]);
      }
      handleClose();
    } catch (err) {
      console.error(err);
      alert('Failed to save email campaign.');
    }
  };

  const handleDelete = async (index) => {
    try {
      const campaignToDelete = campaigns[index];
      await axios.delete(`/api/emailCampaigns/${campaignToDelete._id}`);
      setCampaigns(campaigns.filter((_, i) => i !== index));
    } catch (err) {
      console.error(err);
      alert('Failed to delete email campaign.');
    }
  };

  const renderNurturingLeadsForm = () => (
    <Paper style={{ padding: '20px', marginBottom: '20px' }}>
      <h3>Nurturing Leads</h3>
      <TextField
        label='Name'
        value={nurturingLead?.name || ''}
        onChange={(e) =>
          handleChange('name', e.target.value, 'Nurturing Leads')
        }
        fullWidth
        margin='normal'
        variant='outlined'
      />
      <TextField
        label='Sending Frequency'
        value={nurturingLead?.frequency || ''}
        onChange={(e) =>
          handleChange('frequency', e.target.value, 'Nurturing Leads')
        }
        type='number'
        fullWidth
        margin='normal'
        variant='outlined'
        InputProps={{
          startAdornment: (
            <InputAdornment position='start'>Every</InputAdornment>
          ),
        }}
      />
      <FormControl fullWidth margin='normal' variant='outlined'>
        <InputLabel>Period</InputLabel>
        <Select
          value={nurturingLead?.period || 'Days'}
          onChange={(e) =>
            handleChange('period', e.target.value, 'Nurturing Leads')
          }
          label='Period'
        >
          <MenuItem value='Days'>Days</MenuItem>
          <MenuItem value='Weeks'>Weeks</MenuItem>
          <MenuItem value='Months'>Months</MenuItem>
        </Select>
      </FormControl>
      <TextField
        label='Email Subject'
        value={nurturingLead?.subject || ''}
        onChange={(e) =>
          handleChange('subject', e.target.value, 'Nurturing Leads')
        }
        fullWidth
        margin='normal'
        variant='outlined'
      />
      <TextField
        label='Email Content'
        value={nurturingLead?.content || ''}
        onChange={(e) =>
          handleChange('content', e.target.value, 'Nurturing Leads')
        }
        fullWidth
        margin='normal'
        multiline
        rows={6}
        variant='outlined'
      />
      <Button
        onClick={() => handleSave('Nurturing Leads')}
        color='primary'
        variant='contained'
      >
        Save
      </Button>
    </Paper>
  );

  const renderCuriousLeadsForm = () => (
    <Paper style={{ padding: '20px', marginBottom: '20px' }}>
      <h3>Curious Leads</h3>
      <TextField
        label='Name'
        value={curiousLead?.name || ''}
        onChange={(e) => handleChange('name', e.target.value, 'Curious Leads')}
        fullWidth
        margin='normal'
        variant='outlined'
      />
      <TextField
        label='Sending Frequency'
        value={curiousLead?.frequency || ''}
        onChange={(e) =>
          handleChange('frequency', e.target.value, 'Curious Leads')
        }
        type='number'
        fullWidth
        margin='normal'
        variant='outlined'
        InputProps={{
          startAdornment: (
            <InputAdornment position='start'>Every</InputAdornment>
          ),
        }}
      />
      <FormControl fullWidth margin='normal' variant='outlined'>
        <InputLabel>Period</InputLabel>
        <Select
          value={curiousLead?.period || 'Days'}
          onChange={(e) =>
            handleChange('period', e.target.value, 'Curious Leads')
          }
          label='Period'
        >
          <MenuItem value='Days'>Days</MenuItem>
          <MenuItem value='Weeks'>Weeks</MenuItem>
          <MenuItem value='Months'>Months</MenuItem>
        </Select>
      </FormControl>
      <TextField
        label='Email Subject'
        value={curiousLead?.subject || ''}
        onChange={(e) =>
          handleChange('subject', e.target.value, 'Curious Leads')
        }
        fullWidth
        margin='normal'
        variant='outlined'
      />
      <TextField
        label='Email Content'
        value={curiousLead?.content || ''}
        onChange={(e) =>
          handleChange('content', e.target.value, 'Curious Leads')
        }
        fullWidth
        margin='normal'
        multiline
        rows={6}
        variant='outlined'
      />
      <Button
        onClick={() => handleSave('Curious Leads')}
        color='primary'
        variant='contained'
      >
        Save
      </Button>
    </Paper>
  );

  const renderNotReadyToSellLeadsForm = () => (
    <Paper style={{ padding: '20px', marginBottom: '20px' }}>
      <h3>Not Ready to Sell Leads</h3>
      <TextField
        label='Name'
        value={notReadyToSellLead?.name || ''}
        onChange={(e) =>
          handleChange('name', e.target.value, 'Not Ready to Sell Leads')
        }
        fullWidth
        margin='normal'
        variant='outlined'
      />
      <TextField
        label='Sending Frequency'
        value={notReadyToSellLead?.frequency || ''}
        onChange={(e) =>
          handleChange('frequency', e.target.value, 'Not Ready to Sell Leads')
        }
        type='number'
        fullWidth
        margin='normal'
        variant='outlined'
        InputProps={{
          startAdornment: (
            <InputAdornment position='start'>Every</InputAdornment>
          ),
        }}
      />
      <FormControl fullWidth margin='normal' variant='outlined'>
        <InputLabel>Period</InputLabel>
        <Select
          value={notReadyToSellLead?.period || 'Days'}
          onChange={(e) =>
            handleChange('period', e.target.value, 'Not Ready to Sell Leads')
          }
          label='Period'
        >
          <MenuItem value='Days'>Days</MenuItem>
          <MenuItem value='Weeks'>Weeks</MenuItem>
          <MenuItem value='Months'>Months</MenuItem>
        </Select>
      </FormControl>
      <TextField
        label='Email Subject'
        value={notReadyToSellLead?.subject || ''}
        onChange={(e) =>
          handleChange('subject', e.target.value, 'Not Ready to Sell Leads')
        }
        fullWidth
        margin='normal'
        variant='outlined'
      />
      <TextField
        label='Email Content'
        value={notReadyToSellLead?.content || ''}
        onChange={(e) =>
          handleChange('content', e.target.value, 'Not Ready to Sell Leads')
        }
        fullWidth
        margin='normal'
        multiline
        rows={6}
        variant='outlined'
      />
      <Button
        onClick={() => handleSave('Not Ready to Sell Leads')}
        color='primary'
        variant='contained'
      >
        Save
      </Button>
    </Paper>
  );

  return (
    <div style={{ padding: '20px' }}>
      <h2>Email Campaigns</h2>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Sending Frequency</TableCell>
              <TableCell>Period</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {campaigns.map((campaign, index) => (
              <TableRow key={campaign._id}>
                <TableCell>{campaign.name}</TableCell>
                <TableCell>Every {campaign.frequency}</TableCell>
                <TableCell>{campaign.period}</TableCell>
                <TableCell>
                  <IconButton
                    color='primary'
                    onClick={() => handleEdit(campaign)}
                  >
                    <EditIcon />
                  </IconButton>
                  <IconButton
                    color='secondary'
                    onClick={() => handleDelete(index)}
                  >
                    <DeleteIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Button
        onClick={handleAddCampaign}
        style={{ marginTop: '20px', marginBottom: '20px' }}
        variant='outlined'
        color='primary'
      >
        + Add Email
      </Button>

      {renderNurturingLeadsForm()}
      {renderCuriousLeadsForm()}
      {renderNotReadyToSellLeadsForm()}

      <Dialog open={open} onClose={handleClose} fullWidth maxWidth='md'>
        <DialogTitle>{dialogTitle}</DialogTitle>
        <DialogContent>
          {selectedCampaign && (
            <div>
              <TextField
                label='Name'
                value={selectedCampaign.name}
                onChange={(e) => handleChange('name', e.target.value, 'Others')}
                fullWidth
                margin='normal'
                variant='outlined'
              />
              <TextField
                label='Sending Frequency'
                value={selectedCampaign.frequency}
                onChange={(e) =>
                  handleChange('frequency', e.target.value, 'Others')
                }
                type='number'
                fullWidth
                margin='normal'
                variant='outlined'
                InputProps={{
                  startAdornment: (
                    <InputAdornment position='start'>Every</InputAdornment>
                  ),
                }}
              />
              <FormControl fullWidth margin='normal' variant='outlined'>
                <InputLabel>Period</InputLabel>
                <Select
                  value={selectedCampaign.period}
                  onChange={(e) =>
                    handleChange('period', e.target.value, 'Others')
                  }
                  label='Period'
                >
                  <MenuItem value='Days'>Days</MenuItem>
                  <MenuItem value='Weeks'>Weeks</MenuItem>
                  <MenuItem value='Months'>Months</MenuItem>
                </Select>
              </FormControl>
              <TextField
                label='Email Subject'
                value={selectedCampaign.subject}
                onChange={(e) =>
                  handleChange('subject', e.target.value, 'Others')
                }
                fullWidth
                margin='normal'
                variant='outlined'
              />
              <TextField
                label='Email Content'
                value={selectedCampaign.content}
                onChange={(e) =>
                  handleChange('content', e.target.value, 'Others')
                }
                fullWidth
                margin='normal'
                multiline
                rows={6}
                variant='outlined'
              />
            </div>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color='secondary'>
            Cancel
          </Button>
          <Button onClick={() => handleSave('Others')} color='primary'>
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default EmailCampaigns;

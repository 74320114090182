import React, { useEffect, useState } from 'react';
import axios from '../utils/axios';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from '@mui/material';

const Subscribers = () => {
  const [subscribers, setSubscribers] = useState([]);

  useEffect(() => {
    const fetchSubscribers = async () => {
      try {
        const res = await axios.get('/api/subscribers');
        setSubscribers(res.data);
      } catch (err) {
        console.error(err);
        alert('Failed to fetch subscribers.');
      }
    };

    fetchSubscribers();
  }, []);

  function formatDate(dateString) {
    if (!dateString) return null;

    const date = new Date(dateString);
    const options = { year: 'numeric', month: 'short', day: 'numeric' };
    return new Intl.DateTimeFormat('en-US', options).format(date);
  }

  return (
    <div style={{ padding: '20px' }}>
      <h2> Subscribers</h2>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Email</TableCell>
              <TableCell>Subscribed Date</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {subscribers.map((subscriber) => (
              <TableRow key={subscriber.id}>
                <TableCell>{subscriber.username}</TableCell>
                <TableCell>{subscriber.email}</TableCell>
                <TableCell>{formatDate(subscriber.subscribedDate)}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default Subscribers;

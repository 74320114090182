import React, { useState, useEffect } from 'react';
import axios from '../utils/axios';
import './Notifications.css';
import { useAuth } from '../AuthContext';
import {
  TextField,
  Grid,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
} from '@mui/material';

const Notifications = () => {
  const { user } = useAuth();
  const [incomingLeads, setIncomingLeads] = useState([
    { type: 'email', value: '' },
  ]);
  const [calendarNotifications, setCalendarNotifications] = useState([
    { type: 'email', value: '', timeValue: 0, timeUnit: 'minutes' },
  ]);
  const [adminNotificationId, setAdminNotificationId] = useState(null);
  const [lockoutNotifications, setLockoutNotifications] = useState([
    { name: 'Lockout After', frequency: 0, period: 'Hours' },
    { name: 'Ready to Sell', frequency: 0, period: 'Days' },
  ]);
  const [agents, setAgents] = useState([]);
  const [followUpEmail, setFollowUpEmail] = useState({
    agentId: '',
    subject: '',
    body: '',
  });

  useEffect(() => {
    fetchNotifications();
    if (user?.role === 'admin') {
      fetchAdminNotifications();
      fetchAgents();
    }
  }, [user?.role]);

  const fetchAgents = async () => {
    try {
      const res = await axios.get('/api/users/agents');
      setAgents(res.data);
    } catch (err) {
      console.error('Error fetching agents:', err);
    }
  };

  const fetchNotifications = async () => {
    try {
      const res = await axios.get('/api/notifications');
      const { incomingLeads = [], calendarNotifications = [] } =
        res.data.notifications;

      setIncomingLeads(
        incomingLeads.length > 0
          ? incomingLeads
          : [{ type: 'email', value: '' }]
      );
      setCalendarNotifications(
        calendarNotifications.length > 0
          ? calendarNotifications
          : [{ type: 'email', value: '', timeValue: 0, timeUnit: 'minutes' }]
      );
    } catch (err) {
      console.error('Error fetching notifications:', err);
    }
  };

  const fetchAdminNotifications = async () => {
    try {
      const res = await axios.get('/api/adminNotifications');
      const { _id, lockoutNotifications = [] } = res.data.adminNotifications;

      setAdminNotificationId(_id);
      setLockoutNotifications(
        lockoutNotifications.length > 0
          ? lockoutNotifications
          : [
              { name: 'Lockout After', frequency: 0, period: 'Hours' },
              { name: 'Ready to Sell', frequency: 0, period: 'Days' },
            ]
      );
    } catch (err) {
      console.error('Error fetching admin notifications:', err);
    }
  };

  const handleNotificationChange = (index, field, value, setter) => {
    setter((prev) => {
      const updatedNotifications = [...prev];
      updatedNotifications[index][field] = value;
      return updatedNotifications;
    });
  };

  const handleAddNotification = (setter, defaultValues = {}) => {
    setter((prev) => [...prev, { type: 'email', value: '', ...defaultValues }]);
  };

  const handleRemoveNotification = (index, setter) => {
    setter((prev) => prev.filter((_, i) => i !== index));
  };

  const handleSave = async () => {
    try {
      const notifications = {
        incomingLeads: incomingLeads.map((n) => ({
          type: n.type,
          value: n.value,
        })),
        calendarNotifications: calendarNotifications.map((n) => ({
          type: n.type,
          value: n.value,
          timeValue: n.timeValue,
          timeUnit: n.timeUnit,
        })),
      };

      const validateNotifications = (notifications) => {
        return notifications.every(
          (notification) => notification.type && notification.value
        );
      };

      if (
        !validateNotifications(incomingLeads) ||
        !validateNotifications(calendarNotifications)
      ) {
        alert('Please fill out all notification fields before saving.');
        return;
      }

      await axios.post('/api/notifications', notifications);
      alert('Notifications saved successfully.');
      fetchNotifications();
    } catch (err) {
      console.error('Error saving notifications:', err);
      alert('Failed to save notifications.');
    }
  };

  const handleSaveAdminNotifications = async () => {
    try {
      const adminNotifications = { lockoutNotifications };
      if (adminNotificationId) {
        await axios.put(
          `/api/adminNotifications/${adminNotificationId}`,
          adminNotifications
        );
      } else {
        await axios.post('/api/adminNotifications', adminNotifications);
      }
      alert('Admin notifications saved successfully.');
      setAdminNotificationId(null);
      fetchAdminNotifications();
    } catch (err) {
      console.error('Error saving admin notifications:', err);
      alert('Failed to save admin notifications.');
    }
  };

  const handleFollowUpEmailChange = (field, value) => {
    setFollowUpEmail((prev) => ({ ...prev, [field]: value }));
  };

  const handleSendFollowUpEmail = async () => {
    try {
      const { agentId, subject, body } = followUpEmail;

      if (!agentId || !subject || !body) {
        alert('Please fill out all fields before sending.');
        return;
      }

      await axios.post('/api/notifications/followUp', followUpEmail);
      alert('Follow Up Email sent successfully.');
    } catch (err) {
      console.error('Error sending Follow Up Email:', err);
      alert('Failed to send Follow Up Email.');
    }
  };

  const handleLockoutChange = (index, field, value) => {
    setLockoutNotifications((prev) => {
      const updatedNotifications = [...prev];
      updatedNotifications[index][field] = value;
      return updatedNotifications;
    });
  };

  const renderNotificationRepeater = (
    notifications,
    setNotifications,
    title,
    additionalFields = null
  ) => (
    <div className='notification-section'>
      <h2>{title}</h2>
      {notifications.map((notification, index) => (
        <div key={index} className='notification-item'>
          <select
            value={notification.type}
            onChange={(e) =>
              handleNotificationChange(
                index,
                'type',
                e.target.value,
                setNotifications
              )
            }
          >
            <option value='email'>Email</option>
            <option value='sms'>SMS</option>
          </select>
          <input
            type='text'
            placeholder={
              notification.type === 'email' ? 'Email Address' : 'Phone Number'
            }
            value={notification.value}
            onChange={(e) =>
              handleNotificationChange(
                index,
                'value',
                e.target.value,
                setNotifications
              )
            }
          />
          {additionalFields &&
            additionalFields(notification, index, setNotifications)}
          <button
            onClick={() => handleRemoveNotification(index, setNotifications)}
          >
            Remove
          </button>
        </div>
      ))}
      <button onClick={() => handleAddNotification(setNotifications)}>
        Add Notification
      </button>
    </div>
  );

  const renderCalendarNotificationRepeater = (
    notifications,
    setNotifications,
    title
  ) => (
    <div className='notification-section'>
      <h2>{title}</h2>
      {notifications.map((notification, index) => (
        <div key={index} className='notification-item'>
          <select
            value={notification.type}
            onChange={(e) =>
              handleNotificationChange(
                index,
                'type',
                e.target.value,
                setNotifications
              )
            }
          >
            <option value='email'>Email</option>
            <option value='sms'>SMS</option>
          </select>
          <input
            type='text'
            placeholder={
              notification.type === 'email' ? 'Email Address' : 'Phone Number'
            }
            value={notification.value}
            onChange={(e) =>
              handleNotificationChange(
                index,
                'value',
                e.target.value,
                setNotifications
              )
            }
          />
          <input
            type='number'
            placeholder='Time Value'
            value={notification.timeValue}
            onChange={(e) =>
              handleNotificationChange(
                index,
                'timeValue',
                e.target.value,
                setNotifications
              )
            }
          />
          <select
            value={notification.timeUnit}
            onChange={(e) =>
              handleNotificationChange(
                index,
                'timeUnit',
                e.target.value,
                setNotifications
              )
            }
          >
            <option value='minutes'>Minutes Before</option>
            <option value='hours'>Hours Before</option>
            <option value='days'>Days Before</option>
          </select>
          <button
            onClick={() => handleRemoveNotification(index, setNotifications)}
          >
            Remove
          </button>
        </div>
      ))}
      <button
        onClick={() =>
          handleAddNotification(setNotifications, {
            timeValue: 0,
            timeUnit: 'minutes',
          })
        }
      >
        Add Notification
      </button>
    </div>
  );

  return (
    <div className='notifications'>
      <h1>Notifications</h1>
      {renderNotificationRepeater(
        incomingLeads,
        setIncomingLeads,
        'Incoming Leads'
      )}
      {renderCalendarNotificationRepeater(
        calendarNotifications,
        setCalendarNotifications,
        'Calendar Event Notifications'
      )}
      <button onClick={handleSave}>Save Notifications</button>

      {user?.role === 'admin' && (
        <>
          <div className='notification-section'>
            <h2 style={{ marginBottom: '20px' }}>Lockout Notification</h2>
            <Grid
              container
              spacing={2}
              marginBottom={2}
              alignItems='center'
              key={0}
            >
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  type='number'
                  label={lockoutNotifications[0].name}
                  value={lockoutNotifications[0].frequency}
                  onChange={(e) =>
                    handleLockoutChange(0, 'frequency', e.target.value)
                  }
                />
              </Grid>
              <Grid item xs={6}>
                <FormControl fullWidth>
                  <InputLabel>Period</InputLabel>
                  <Select
                    value={lockoutNotifications[0].period}
                    onChange={(e) =>
                      handleLockoutChange(0, 'period', e.target.value)
                    }
                    label='Period'
                    readOnly
                  >
                    <MenuItem value='Hours'>Hours</MenuItem>
                    <MenuItem value='Days'>Days</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          </div>
          <button onClick={handleSaveAdminNotifications}>
            Save Lockout Notifications
          </button>

          <div className='notification-section'>
            <h2 style={{ marginBottom: '20px' }}>
              Ready to Sell Notifications
            </h2>
            <Grid
              container
              spacing={2}
              marginBottom={2}
              alignItems='center'
              key={1}
            >
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  type='number'
                  label={lockoutNotifications[1].name}
                  value={lockoutNotifications[1].frequency}
                  onChange={(e) =>
                    handleLockoutChange(1, 'frequency', e.target.value)
                  }
                />
              </Grid>
              <Grid item xs={6}>
                <FormControl fullWidth>
                  <InputLabel>Period</InputLabel>
                  <Select
                    value={lockoutNotifications[1].period}
                    onChange={(e) =>
                      handleLockoutChange(1, 'period', e.target.value)
                    }
                    label='Period'
                    readOnly
                  >
                    <MenuItem value='Hours'>Hours</MenuItem>
                    <MenuItem value='Days'>Days</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          </div>
          <button onClick={handleSaveAdminNotifications}>
            Save Ready to Sell Notifications
          </button>

          <div className='follow-up-email'>
            <h2>Follow Up Email</h2>
            <div className='notification-item'>
              <select
                value={followUpEmail.agentId}
                onChange={(e) =>
                  handleFollowUpEmailChange('agentId', e.target.value)
                }
              >
                <option value=''>Select Agent</option>
                {agents.map((agent) => (
                  <option key={agent._id} value={agent._id}>
                    {agent.username}
                  </option>
                ))}
              </select>
              <input
                type='text'
                placeholder='Subject'
                value={followUpEmail.subject}
                onChange={(e) =>
                  handleFollowUpEmailChange('subject', e.target.value)
                }
              />
              <textarea
                placeholder='Email Body'
                value={followUpEmail.body}
                rows={8}
                onChange={(e) =>
                  handleFollowUpEmailChange('body', e.target.value)
                }
              />
            </div>
            <button onClick={handleSendFollowUpEmail}>Send</button>
          </div>
        </>
      )}
    </div>
  );
};

export default Notifications;

import React, { useState, useEffect } from 'react';
import {
  Container,
  Typography,
  TextField,
  Button,
  Paper,
  FormControl,
  FormLabel,
  Select,
  MenuItem,
  Alert,
  Box,
} from '@mui/material';
import axios from '../utils/axios';
import { useParams } from 'react-router-dom';

const LeadSurvey = () => {
  const { leadId } = useParams();
  const [surveyData, setSurveyData] = useState({
    comments: '',
    status: 'Ready to Sell',
  });
  const [errors, setErrors] = useState({});
  const [isValid, setIsValid] = useState(null);
  const [message, setMessage] = useState('');

  useEffect(() => {
    const fetchLeadData = async () => {
      try {
        const res = await axios.get(`api/leads/leadSurvey/${leadId}`);
        if (res.status === 200) {
          setIsValid(true);
        }
      } catch (err) {
        setIsValid(false);
        setMessage(err.response.data.message || 'Error fetching lead data.');
      }
    };

    fetchLeadData();
  }, [leadId]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setSurveyData({ ...surveyData, [name]: value });
  };

  const validateForm = () => {
    const errors = {};
    if (!surveyData.comments) errors.comments = 'This field is required';
    if (!surveyData.status) errors.status = 'This field is required';
    return errors;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const validationErrors = validateForm();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }
    setErrors({});
    try {
      await axios.post(`api/leads/leadSurvey/${leadId}`, surveyData);
      setMessage('Survey submitted successfully!');
      setIsValid(false);
    } catch (err) {
      setMessage(
        'Error submitting survey: ' +
          (err.response?.data?.message || err.message)
      );
      setIsValid(false);
    }
  };

  if (isValid === null) {
    return (
      <Container maxWidth='sm'>
        <Typography variant='h6' align='center' style={{ marginTop: '32px' }}>
          Loading...
        </Typography>
      </Container>
    );
  }

  return (
    <Container maxWidth='sm'>
      <Paper
        elevation={3}
        style={{
          marginTop: '32px',
          padding: '32px',
          backgroundColor: '#f4f6f8',
          borderRadius: '8px',
        }}
      >
        <Typography variant='h4' align='center' gutterBottom>
          Lead Survey
        </Typography>
        {isValid ? (
          <form onSubmit={handleSubmit}>
            <Box mb={3}>
              <FormControl
                component='fieldset'
                error={!!errors.comments}
                fullWidth
              >
                <FormLabel component='legend'>
                  How did the appointment go?
                </FormLabel>
                <TextField
                  name='comments'
                  value={surveyData.comments}
                  onChange={handleChange}
                  multiline
                  rows={4}
                  variant='outlined'
                  fullWidth
                  style={{ marginTop: '16px' }}
                />
                {errors.comments && (
                  <Typography color='error'>{errors.comments}</Typography>
                )}
              </FormControl>
            </Box>
            <Box mb={3}>
              <FormControl
                component='fieldset'
                error={!!errors.status}
                fullWidth
              >
                <FormLabel component='legend'>Lead Status</FormLabel>
                <Select
                  name='status'
                  value={surveyData.status}
                  onChange={handleChange}
                  variant='outlined'
                  fullWidth
                  style={{ marginTop: '16px' }}
                >
                  <MenuItem value='Ready to Sell'>Ready to Sell</MenuItem>
                  <MenuItem value='Not Ready to Sell'>
                    Not Ready to Sell
                  </MenuItem>
                </Select>
                {errors.status && (
                  <Typography color='error'>{errors.status}</Typography>
                )}
              </FormControl>
            </Box>
            <Button
              type='submit'
              variant='contained'
              color='primary'
              fullWidth
              style={{ marginTop: '32px' }}
            >
              Submit
            </Button>
          </form>
        ) : (
          <Alert severity={message.includes('submitted') ? 'success' : 'error'}>
            {message}
          </Alert>
        )}
      </Paper>
    </Container>
  );
};

export default LeadSurvey;

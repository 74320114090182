// Agents.js
import React, { useEffect, useState } from 'react';
import axios from '../utils/axios';
import './Agents.css';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

const Agents = () => {
  const [agents, setAgents] = useState([]);
  const [open, setOpen] = useState(false);
  const [editOpen, setEditOpen] = useState(false);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [newAgent, setNewAgent] = useState({
    username: '',
    role: 'agent',
    password: 'password',
  });
  const [selectedAgent, setSelectedAgent] = useState(null);
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [agentLeads, setAgentLeads] = useState([]); // State to store leads for selected agent

  useEffect(() => {
    const fetchAgents = async () => {
      try {
        const res = await axios.get('/api/users/agents');
        setAgents(res.data);
      } catch (err) {
        console.error(err);
        alert('Failed to fetch agents.');
      }
    };

    fetchAgents();
  }, []);

  const fetchAgentLeads = async (agentId) => {
    try {
      const res = await axios.get(`/api/leads/agent/${agentId}`);
      setAgentLeads(res.data);
    } catch (err) {
      console.error(err);
      alert('Failed to fetch leads for agent.');
    }
  };

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const handleEditOpen = () => setEditOpen(true);
  const handleEditClose = () => setEditOpen(false);
  const handleDeleteOpen = () => setDeleteOpen(true);
  const handleDeleteClose = () => setDeleteOpen(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setNewAgent({ ...newAgent, [name]: value });
  };

  const handleAddAgent = async (e) => {
    e.preventDefault();
    console.log('Adding agent with data:', newAgent);
    if (!['admin', 'agent'].includes(newAgent.role)) {
      alert('Invalid role. Please select a valid role.');
      return;
    }
    try {
      const res = await axios.post('/api/auth/users/register', newAgent);
      setAgents([...agents, res.data]);
      handleClose();
    } catch (err) {
      console.error('Error adding agent:', err);
      console.error('Response:', err.response);
      if (err.response && err.response.data) {
        alert(`Failed to add agent: ${err.response.data.msg}`);
      } else {
        alert('Failed to add agent.');
      }
    }
  };

  const handleEditAgent = async (e) => {
    e.preventDefault();
    try {
      const res = await axios.put(
        `/api/auth/users/${selectedAgent._id}`,
        newAgent
      );
      const updatedAgents = agents.map((agent) =>
        agent._id === res.data._id ? res.data : agent
      );
      setAgents(updatedAgents);
      handleEditClose();
    } catch (err) {
      console.error('Error editing agent:', err);
      console.error('Response:', err.response);
      if (err.response && err.response.data) {
        alert(`Failed to edit agent: ${err.response.data.msg}`);
      } else {
        alert('Failed to edit agent.');
      }
    }
  };

  const handleDeleteAgent = async () => {
    try {
      await axios.delete(`/api/auth/users/${selectedAgent._id}`);
      const updatedAgents = agents.filter(
        (agent) => agent._id !== selectedAgent._id
      );
      setAgents(updatedAgents);
      handleDeleteClose();
      setSelectedAgent(null);
      setSidebarOpen(false);
    } catch (err) {
      console.error('Error deleting agent:', err);
      console.error('Response:', err.response);
      if (err.response && err.response.data) {
        alert(`Failed to delete agent: ${err.response.data.msg}`);
      } else {
        alert('Failed to delete agent.');
      }
    }
  };

  const handleAgentClick = (agent) => {
    setSelectedAgent(agent);
    setNewAgent({ username: agent.username, role: agent.role });
    setSidebarOpen(true);
    fetchAgentLeads(agent._id); // Fetch leads for the selected agent
  };

  const handleSidebarClose = () => {
    setSidebarOpen(false);
    setSelectedAgent(null);
    setAgentLeads([]); // Clear leads when closing the sidebar
  };

  return (
    <div className='agents'>
      <div className='toolbar'>
        <Button variant='contained' onClick={handleOpen}>
          <AddIcon /> Add Agent
        </Button>
      </div>
      <table>
        <thead>
          <tr>
            <th>Username</th>
            <th>Role</th>
          </tr>
        </thead>
        <tbody>
          {agents.map((agent, index) => (
            <tr
              key={agent._id}
              className={index % 2 === 0 ? 'even' : 'odd'}
              onClick={() => handleAgentClick(agent)}
            >
              <td>{agent.username}</td>
              <td>{agent.role}</td>
            </tr>
          ))}
        </tbody>
      </table>

      <Modal open={open} onClose={handleClose}>
        <Box className='modal-box'>
          <h2>Add New Agent</h2>
          <form onSubmit={handleAddAgent}>
            <TextField
              label='Username'
              name='username'
              value={newAgent.username}
              onChange={handleChange}
              fullWidth
              margin='normal'
              required
            />
            <TextField
              select
              label='Role'
              name='role'
              value={newAgent.role}
              onChange={handleChange}
              fullWidth
              margin='normal'
              required
              SelectProps={{
                native: true,
              }}
            >
              <option value='agent'>Agent</option>
              <option value='admin'>Admin</option>
            </TextField>
            <TextField
              label='Password'
              name='password'
              value={newAgent.password}
              onChange={handleChange}
              fullWidth
              margin='normal'
              required
            />
            <Button type='submit' variant='contained' color='primary'>
              Add Agent
            </Button>
          </form>
        </Box>
      </Modal>

      <Modal open={editOpen} onClose={handleEditClose}>
        <Box className='modal-box'>
          <h2>Edit Agent</h2>
          <form onSubmit={handleEditAgent}>
            <TextField
              label='Username'
              name='username'
              value={newAgent.username}
              onChange={handleChange}
              fullWidth
              margin='normal'
              required
            />
            <TextField
              select
              label='Role'
              name='role'
              value={newAgent.role}
              onChange={handleChange}
              fullWidth
              margin='normal'
              required
              SelectProps={{
                native: true,
              }}
            >
              <option value='agent'>Agent</option>
              <option value='admin'>Admin</option>
            </TextField>
            <Button type='submit' variant='contained' color='primary'>
              Save Changes
            </Button>
          </form>
        </Box>
      </Modal>

      <Dialog open={deleteOpen} onClose={handleDeleteClose}>
        <DialogTitle>Delete Agent</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete this agent?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDeleteClose} color='primary'>
            Cancel
          </Button>
          <Button onClick={handleDeleteAgent} color='primary'>
            Delete
          </Button>
        </DialogActions>
      </Dialog>

      <div className={`right-sidebar ${sidebarOpen ? 'open' : ''}`}>
        <div className='content'>
          {selectedAgent ? (
            <>
              <h2>{selectedAgent.username}</h2>
              <p>Role: {selectedAgent.role}</p>
              <Button
                onClick={handleEditOpen}
                variant='contained'
                startIcon={<EditIcon />}
              >
                Edit
              </Button>
              <Button
                onClick={handleDeleteOpen}
                variant='contained'
                color='secondary'
                startIcon={<DeleteIcon />}
              >
                Delete
              </Button>
              <h3>Assigned Leads:</h3>
              <ul className='assigned-leads-list'>
                {agentLeads.length > 0 ? (
                  agentLeads.map((lead) => (
                    <li key={lead._id} className='log-entry log-update'>
                      {lead.name} - {lead.email} - {lead.phone} -{' '}
                      {lead.municipality}
                    </li>
                  ))
                ) : (
                  <p>No leads assigned to this agent.</p>
                )}
              </ul>
              <Button
                onClick={handleSidebarClose}
                variant='contained'
                color='primary'
              >
                Close
              </Button>
            </>
          ) : (
            <p>Select an agent to view details</p>
          )}
        </div>
      </div>
    </div>
  );
};

export default Agents;

// src/components/Website.js
import React from 'react';
import './Website.css';

const Website = () => {
  return (
    <div className="website">
      <h1>Website</h1>
      <p>This is the Website page. You can manage website settings here.</p>
      {/* You can add website management features here */}
    </div>
  );
};

export default Website;

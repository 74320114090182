import React, { createContext, useState, useContext } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { baseURL } from './utils/constants';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const navigate = useNavigate();

  const [user, setUser] = useState(() => {
    const storedUser = localStorage.getItem('user');
    return storedUser ? JSON.parse(storedUser) : null;
  });

  const login = async (username, password) => {
    try {
      const res = await axios.post(`${baseURL}/api/auth/login`, {
        username,
        password,
      });
      setUser(res.data.user);
      localStorage.setItem('user', JSON.stringify(res.data.user));
      localStorage.setItem('token', res.data.token);
      navigate('/leads');
    } catch (err) {
      if (err.response && err.response.data) {
        alert(err.response.data.msg);
      } else {
        alert('An error occurred. Please try again.');
      }
    }
  };

  const logout = async () => {
    const res = await axios.post(`${baseURL}/api/auth/logout`).then(() => {
      setUser(null);
      localStorage.removeItem('user');
      localStorage.removeItem('token');
      localStorage.removeItem('storeUser');
      navigate('/');
    }).catch(e => {
      console.log(e);
      setUser(null);
      localStorage.removeItem('user');
      localStorage.removeItem('storeUser');
      localStorage.removeItem('token');
      navigate('/');
    })
  };

  return (
    <AuthContext.Provider value={{ user, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);

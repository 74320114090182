// utils/axios.js
import axios from 'axios';
import { baseURL } from './constants';

const instance = axios.create({
  baseURL: baseURL, // Update this if your backend is running on a different port
});

instance.interceptors.request.use((config) => {
  const token = localStorage.getItem('token');
  if (token) {
    config.headers['x-auth-token'] = token;
  }
  return config;
});

instance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    const originalRequest = error.config;
    if (
      error.response &&
      error.response.status === 401 &&
      originalRequest.url !== '/api/auth/updatePassword'
    ) {
      localStorage.removeItem('token');
      localStorage.removeItem('user');

      window.location.reload();
    }
    return Promise.reject(error);
  }
);

export default instance;

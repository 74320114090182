import React, { useState, useEffect } from 'react';
import { Calendar as BigCalendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import axios from '../utils/axios';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import './Calendar.css';
import Button from '@mui/material/Button';
import { useAuth } from '../AuthContext';

const localizer = momentLocalizer(moment);

const Calendar = () => {
  const { user } = useAuth();
  const [events, setEvents] = useState([]);
  const [view, setView] = useState('month');
  const [date, setDate] = useState(new Date());
  const [newEvent, setNewEvent] = useState({
    eventName: '',
    eventDate: '',
    leadId: '',
  });
  const [leads, setLeads] = useState([]);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [sidebarOpen, setSidebarOpen] = useState(false);

  useEffect(() => {
    fetchEvents();
    fetchLeads();
  }, []);

  const fetchEvents = async () => {
    try {
      const res = await axios.get('/api/events');
      const formattedEvents = res.data.map((event) => ({
        ...event,
        title: event.eventName,
        start: new Date(event.eventDate),
        end: new Date(event.eventDate),
      }));
      setEvents(formattedEvents);
    } catch (err) {
      console.error('Error fetching events:', err);
    }
  };

  const fetchLeads = async () => {
    try {
      let endpoint = '/api/leads/me';
      if (user?.role === 'admin') endpoint = '/api/leads';
      const res = await axios.get(endpoint);
      setLeads(res.data);
    } catch (err) {
      console.error('Error fetching leads:', err);
    }
  };

  const handleViewChange = (view) => {
    setView(view);
  };

  const handleNavigate = (date) => {
    setDate(date);
  };

  const handleEventChange = (e) => {
    const { name, value } = e.target;
    setNewEvent({ ...newEvent, [name]: value });
  };

  const handleAddEvent = async (e) => {
    e.preventDefault();
    try {
      const newEventFormatted = {
        eventName: newEvent.eventName,
        eventDate: new Date(newEvent.eventDate),
        leadId: newEvent.leadId,
      };

      const res = await axios.post('/api/events', newEventFormatted);
      setEvents([
        ...events,
        {
          ...res.data,
          title: res.data.eventName,
          start: new Date(res.data.eventDate),
          end: new Date(res.data.eventDate),
        },
      ]);
      setNewEvent({ eventName: '', eventDate: '', leadId: '' });
    } catch (err) {
      console.error('Error adding event:', err);
    }
  };

  const handleEventClick = (event) => {
    setSelectedEvent(event);
    setSidebarOpen(true);
  };

  const handleSidebarClose = () => {
    setSidebarOpen(false);
    setSelectedEvent(null);
  };

  const handleDeleteEvent = async () => {
    try {
      await axios.delete(`/api/events/${selectedEvent._id}`);
      setEvents(events.filter((event) => event._id !== selectedEvent._id));
      handleSidebarClose();
    } catch (err) {
      console.error('Error deleting event:', err);
    }
  };

  return (
    <div className='calendar-container'>
      <div className='calendar-toolbar'>
        <button onClick={() => handleViewChange('day')}>Today</button>
        <button onClick={() => handleViewChange('week')}>This Week</button>
        <button onClick={() => handleViewChange('month')}>This Month</button>
      </div>
      <form className='event-form' onSubmit={handleAddEvent}>
        <input
          type='text'
          name='eventName'
          placeholder='Event Title'
          value={newEvent.eventName}
          onChange={handleEventChange}
          required
        />
        <input
          type='datetime-local'
          name='eventDate'
          placeholder='Event Date'
          value={newEvent.eventDate}
          onChange={handleEventChange}
          required
        />
        <select
          name='leadId'
          value={newEvent.leadId}
          onChange={handleEventChange}
        >
          <option value=''>Select Lead</option>
          {leads.map((lead) => (
            <option key={lead._id} value={lead._id}>
              {lead.name}
            </option>
          ))}
        </select>
        <button type='submit'>Add Event</button>
      </form>
      <BigCalendar
        localizer={localizer}
        events={events}
        startAccessor='start'
        endAccessor='end'
        style={{ height: 500 }}
        date={date}
        view={view}
        onView={handleViewChange}
        onNavigate={handleNavigate}
        onSelectEvent={handleEventClick}
      />
      {sidebarOpen && selectedEvent && (
        <div className='right-sidebar open'>
          <div className='content'>
            <h2>Event Details</h2>
            <p>
              <strong>Title:</strong> {selectedEvent.title}
            </p>
            <p>
              <strong>Date:</strong>{' '}
              {new Date(selectedEvent.start).toLocaleString()}
            </p>
            <p>
              <strong>Lead:</strong>{' '}
              {selectedEvent.leadId
                ? leads.find((lead) => lead._id === selectedEvent.leadId)?.name
                : 'None'}
            </p>
            <Button
              onClick={handleSidebarClose}
              variant='contained'
              color='primary'
            >
              Close
            </Button>
            <Button
              onClick={handleDeleteEvent}
              variant='contained'
              color='secondary'
            >
              Delete Event
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};

export default Calendar;

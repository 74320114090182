import React, { useState, useEffect } from 'react';
import {
  Container,
  Typography,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  CircularProgress,
  Alert,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import axios from '../utils/axios';
import { useAuth } from '../AuthContext';

const SurveyForms = () => {
  const { user } = useAuth();
  const navigate = useNavigate();
  const [pendingForms, setPendingForms] = useState([]);
  const [overdueForms, setOverdueForms] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchPendingLeads = async () => {
      try {
        const res = await axios.get('/api/leads/myPendingLeads');
        const leads = res.data;

        const pending = leads.filter((lead) => !lead.lockoutApplies);
        const overdue = leads.filter((lead) => lead.lockoutApplies);

        setPendingForms(pending);
        setOverdueForms(overdue);
        setLoading(false);
      } catch (err) {
        console.error('Error fetching leads:', err);
        setLoading(false);
      }
    };

    if (user) {
      fetchPendingLeads();
    }
  }, [user]);

  const handleRowClick = (leadId) => {
    navigate(`/leadSurvey/${leadId}`);
  };

  if (loading) {
    return (
      <Container maxWidth='md'>
        <CircularProgress />
      </Container>
    );
  }

  return (
    <Container maxWidth='lg'>
      {overdueForms && overdueForms.length > 0 && (
        <Alert severity='warning' style={{ marginBottom: '20px' }}>
          You have been locked out of the system until you complete the overdue
          forms.
        </Alert>
      )}
      <Typography variant='h4' align='center' marginBottom={5} gutterBottom>
        Survey Forms
      </Typography>

      <Paper
        elevation={3}
        style={{
          padding: '20px',
          marginBottom: '40px',
          backgroundColor: '#ffe6e6',
        }}
      >
        <Typography variant='h6' gutterBottom>
          Overdue Forms
        </Typography>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Lead Name</TableCell>
                <TableCell>Email</TableCell>
                <TableCell>Phone</TableCell>
                <TableCell>Approved At</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {overdueForms.map((lead) => (
                <TableRow
                  key={lead._id}
                  onClick={() => handleRowClick(lead._id)}
                  style={{ cursor: 'pointer' }}
                >
                  <TableCell>{lead.name}</TableCell>
                  <TableCell>{lead.email}</TableCell>
                  <TableCell>{lead.phone}</TableCell>
                  <TableCell>
                    {new Date(lead.approvedAt).toLocaleString()}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>

      <Paper elevation={3} style={{ padding: '20px' }}>
        <Typography variant='h6' gutterBottom>
          Pending Forms
        </Typography>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Lead Name</TableCell>
                <TableCell>Email</TableCell>
                <TableCell>Phone</TableCell>
                <TableCell>Approved At</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {pendingForms.map((lead) => (
                <TableRow
                  key={lead._id}
                  onClick={() => handleRowClick(lead._id)}
                  style={{ cursor: 'pointer' }}
                >
                  <TableCell>{lead.name}</TableCell>
                  <TableCell>{lead.email}</TableCell>
                  <TableCell>{lead.phone}</TableCell>
                  <TableCell>
                    {new Date(lead.approvedAt).toLocaleString()}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </Container>
  );
};

export default SurveyForms;
